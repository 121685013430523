import React, { useState } from "react";
import { BandaContainer, Indent, AuxTitle, Title, AuxCanal, TextCanal, YoutubeImage, TextBanda, AuxBody, AuxImages, CircleImage, AuxTextBanda } from "./styles.js";
import YoutubeIcon from "../../assets/images/icon-youtube.svg";
import YoutubeIconOrange from "../../assets/images/icon-youtube-orange.svg";
import AlanImage from "../../assets/images/alan-image.png";
import BateraImage from "../../assets/images/batera-image.png";
import KleberImage from "../../assets/images/kleber-image.png";
import LaioImage from "../../assets/images/laio-image.png";
 
const Banda = () => {
  const [youtubeIcon, setYoutubeIcon] = useState(YoutubeIcon)

  const handleRedirect = () => {
    window.location.href = 'https://www.youtube.com/@the900tv';
  };

  const handleMouseOver = () => {
    setYoutubeIcon(YoutubeIconOrange)
  };

  const handleMouseLeave = () => {
    setYoutubeIcon(YoutubeIcon)
  };

  return (
    <BandaContainer id="banda">
      <Indent>
        <AuxTitle>
          <Title>a banda</Title>
          <AuxCanal onMouseOver={() => handleMouseOver()} onMouseLeave={() => handleMouseLeave()}  onClick={handleRedirect}>
            <TextCanal>acesse nosso canal</TextCanal>
            <YoutubeImage src={youtubeIcon} />
          </AuxCanal>
        </AuxTitle>
        <AuxBody>
          <AuxImages>
            <CircleImage src={AlanImage} />
            <CircleImage src={BateraImage} />
            <CircleImage src={LaioImage} />
            <CircleImage src={KleberImage} />
          </AuxImages>
          <AuxTextBanda>
            <TextBanda>
              The900 formada em Americana no ano de 2006 conta com 17 anos de estrada. Definido por Régis 
              Tadeu como “sujo e tosco na medida certa" quando a banda ganhou o Festival de Rock de 
              Indaiatuba e abriu portas para shows com artistas renomados como Sepultura, Titãs, 
              Ratos de Porão e Marky Ramone.   
            </TextBanda>
            <TextBanda>
              Passando com seu show enérgico por São Paulo, Rio de Janeiro, Paraná, Santa Catarina e uma turnê na Argentina que contou com 4 shows.
            </TextBanda>
            <TextBanda>
              De volta à estrada, em 2023 a banda lança seu quinto álbum intitulado “5 vidas” resgatando uma sonoridade crua e direta para um público sedento por novidades. Pode vir.
            </TextBanda>
          </AuxTextBanda>
        </AuxBody>
      </Indent>
    </BandaContainer>
  );
};

export default Banda;
