import styled from "styled-components";
import colors from "../../styles/colors";

export const DiscografiaContainer = styled.div`
  width: 100%;
  background: #000;
  padding: 0 0 60px 0;

  @media (max-width: 948px) {

  }
`;

export const Indent = styled.div`
  padding: 0 135px;  

  @media (max-width: 948px) {
    padding: 0 24px;  
  }
`;

export const AuxTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 948px) {

  }
`;

export const Title = styled.h3`  
  color: #FFF;
  text-align: right;
  font-family: Anton;
  font-size: 240px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin: -16px 0;

  @media (max-width: 948px) {
    color: #FFF;
    text-align: right;
    font-family: Anton;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px;
    text-transform: uppercase;
    margin: -6px 0;
  }
`;

export const BlockDisco = styled.div`  
  max-width: 210px;
  width: 100%;
  margin: 0 0 63px 22px;
  cursor: pointer;

  @media (max-width: 948px) {
    width: inherit;
    margin: 0 0 40px 0;
  }
`;

export const Image = styled.img`  
  @media (max-width: 948px) {
    width: 160px;
    height: 160px;
  }
`;

export const TitleDisco = styled.h4`
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin: 20px 0 0 0;

  @media (max-width: 948px) {
    color: #FFF;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.8px;
    text-transform: uppercase;
  }
`;

export const AuxData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0 0 0;

  @media (max-width: 948px) {

  }
`;

export const Data = styled.p`  
  color: #FFF;
  font-family: Anton;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 948px) {
    color: #FFF;
    font-family: Anton;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;

  @media (max-width: 948px) {
    width: 20px;
    height: 20px;
  }
`;

export const AuxBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 112px 0 0 0;

  @media (max-width: 948px) {
    margin: 40px 0 0 0;
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: space-between;
  }
`;