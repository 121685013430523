import styled from "styled-components";
import colors from "../../styles/colors";

export const GaleryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 948px) {
    display: none;
  }
`;

export const GaleryContainerMobile = styled.div`
  display: none;

  @media (max-width: 948px) {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export const AlanImage = styled.img`
  width: 100%;
  height: 39vw;

  @media (max-width: 948px) {
    height: 67vw;
  }
`;

export const LaioImage = styled.img`
  width: 100%;
  height: 50vw;

  @media (max-width: 948px) {
    height: 110vw;
    margin: -4px 0;
  }
`;

export const BateraImage = styled.img`
  width: 100%;
  height: 27vw;
  margin: -4px 0px;

  @media (max-width: 948px) {
    height: 72vw;
  }
`;

export const KleberImage = styled.img`
  width: 100%;
  height: 38vw;
  margin: -4px 0px;

  @media (max-width: 948px) {
    height: 75vw;
  }
`;

export const AuxImageFirst = styled.div`
  width: 60%;

  @media (max-width: 948px) {

  }
`;

export const AuxImageSecond = styled.div`
  width: 40%;

  @media (max-width: 948px) {

  }
`;

export const AllImage = styled.img`
  width: 100%;

  @media (max-width: 948px) {

  }
`;