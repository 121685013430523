import React from "react";
import { FooterContainer, Indent, BlockFirst, 
  BlockSecond, LinkSocials, Aux, Number, Email, 
  Release, TextRodape, FooterContainerMobile, ClickBm, ImageBm } from "./styles.js";
import IconBM from '../../assets/images/icon-bm.svg';
 
const Footer = () => {

  const handleRedirect = () => {
    window.open("https://www.blackmagenta.com.br", '_blank');
  };

  return (
    <>
      <FooterContainer id="footer">
        <Indent>
          <BlockFirst>
            <LinkSocials href="https://www.instagram.com/the900/" target="_blank">instagram</LinkSocials>
            <LinkSocials href="https://www.youtube.com/@the900tv" target="_blank">youtube</LinkSocials>
            <LinkSocials target="_blank" href="https://open.spotify.com/intl-pt/artist/63ShJh3BmhNnT0v2nAlMPN?si=yESYZ1l2Q2iK8UmBm9Q3Ug">spotify</LinkSocials>
          </BlockFirst>
          <BlockSecond>
            <Aux>
              <Number href="https://wa.me/5519991538730" target="_blank">+ 55 19 99153.8730</Number>
              <Email href="mailto:contato@the900.com.br">contato@the900.com.br</Email>
            </Aux>
            <Release href="https://drive.google.com/drive/folders/1SEd4yVbGvFCDybxT5pFO2KgDY_c-vn67" target="_blank">release & fotos</Release>
          </BlockSecond>
        </Indent>
        <TextRodape>the900 © americana . 2023</TextRodape>
        <ClickBm onClick={() => handleRedirect()}>
          <ImageBm src={IconBM} />
        </ClickBm>
      </FooterContainer>
      <FooterContainerMobile id="footer">
        <Number href="https://wa.me/5519991538730" target="_blank">+ 55 19 99153.8730</Number>
        <Email href="mailto:contato@the900.com.br">contato@the900.com.br</Email> 
        <LinkSocials href="https://www.instagram.com/the900/" target="_blank">instagram</LinkSocials>
        <LinkSocials href="https://www.youtube.com/@the900tv" target="_blank">youtube</LinkSocials>
        <LinkSocials target="_blank" href="https://open.spotify.com/intl-pt/artist/63ShJh3BmhNnT0v2nAlMPN?si=yESYZ1l2Q2iK8UmBm9Q3Ug">spotify</LinkSocials>
        <Release href="https://drive.google.com/drive/folders/1SEd4yVbGvFCDybxT5pFO2KgDY_c-vn67" target="_blank">release & fotos</Release>
        <TextRodape>the900 © americana . 2023</TextRodape>
        <ClickBm onClick={() => handleRedirect()}>
          <ImageBm src={IconBM} />
        </ClickBm>
      </FooterContainerMobile>
    </>
  );
};

export default Footer;
