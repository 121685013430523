import React from "react";
import { useSpring, animated } from "react-spring";
import { HeaderContainer, Indent, TextName, Logo, TextSince, ArrowImage } from "./styles.js";
import LogoThe900 from '../../assets/images/logo.png';
import Arrow from '../../assets/images/arrow-down.svg';

const Header = () => {
  const [{ logoOffset, textSinceOffset, arrowOffset }, set] = useSpring(() => ({
    logoOffset: 0,
    textSinceOffset: 0,
    arrowOffset: 0,
  }));

  const handleScroll = () => {
    const yOffset = window.pageYOffset;
    set({
      logoOffset: yOffset * 0.5,
      textSinceOffset: yOffset * 0.5,
      arrowOffset: yOffset * 0.3,
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderContainer id="header">
      <Indent>
        <TextName>Alan Coelho</TextName>
        <TextName>Gabriel Barcello</TextName>
        <TextName>Laio Carvalho</TextName>
        <TextName>Kleber Bovo</TextName>
      </Indent>
      <animated.div style={{ transform: logoOffset.interpolate(offset => `translateY(${offset}px)`), position: "relative", zIndex: 1, width: "100%"  }}>
        <Logo src={LogoThe900} />
      </animated.div>
      <animated.div style={{ transform: textSinceOffset.interpolate(offset => `translateY(${offset}px)`)}}>
        <TextSince>since 2006</TextSince>
      </animated.div>
      <animated.div style={{ transform: arrowOffset.interpolate(offset => `translateY(${offset}px)`) }}>
        <ArrowImage src={Arrow} />
      </animated.div>
    </HeaderContainer>
  );
};

export default Header;
