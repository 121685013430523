import styled from "styled-components";
import colors from "../../styles/colors";

export const HeaderContainer = styled.div`
  width: 100%;
  background: #F5F5F5;
  padding: 30px 0 0 0;

  @media (max-width: 948px) {

  }
`;

export const Indent = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 948px) {
    flex-direction: column;
  }
`;

export const TextName = styled.h2`
  color: #000;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;

  @media (max-width: 948px) {
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
  }
`;

export const Logo = styled.img`
  width: 98%;
  margin: 60px auto 0 auto;
  display: flex;

  @media (max-width: 948px) {

  }
`;

export const TextSince = styled.h3`
  color: var(--cinza-60, #666);
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.4px;
  text-transform: uppercase;
  margin: 60px auto 44px auto;
  display: flex;
  text-align: center;
  justify-content: center;

  @media (max-width: 948px) {

  }
`;

export const ArrowImage = styled.img`
  margin: 0 auto 61px auto;
  display: flex;

  @media (max-width: 948px) {

  }
`;