import styled from "styled-components";
import colors from "../../styles/colors";

export const VideosContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1000;
  display: block;

  @media (max-width: 948px) {
    display: none;
  }
`;

export const VideosContainerMobile = styled.div`
  display: none;

  @media (max-width: 948px) {
    width: 100%;
    position: relative;
    z-index: 1000;
    display: block;

    iframe {
      margin: -4px 0 0 0;
    }
  }
`;


export const AuxVideos = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: -10px 0;

  @media (max-width: 948px) {

  }
`;