import React from "react";
import { GaleryContainer, AlanImage, LaioImage, BateraImage, KleberImage, AuxImageFirst, AuxImageSecond, GaleryContainerMobile, AllImage } from "./styles.js";
import AlanGalery from "../../assets/images/alan-galery.png";
import LaioGalery from "../../assets/images/laio-galery.png";
import BateraGalery from "../../assets/images/batera-galery.png";
import KleberGalery from "../../assets/images/kleber-galery.png";
import AllPhotos from "../../assets/images/all-photos.png";
import AllPhotosMobile from "../../assets/images/all-photos-mobile.png";
 
const Galery = () => {

  return (
    <>
    <GaleryContainer id="galery">
    <AllImage src={AllPhotos} />

      {/* <AuxImageFirst>
        <AlanImage src={AlanGalery} />
        <KleberImage src={KleberGalery} />  
      </AuxImageFirst>
      <AuxImageSecond>
        <LaioImage src={LaioGalery} />
        <BateraImage src={BateraGalery} />
      </AuxImageSecond> */}
    </GaleryContainer>
    <GaleryContainerMobile id="galery">
      {/* <AlanImage src={AlanGalery} />
      <LaioImage src={LaioGalery} />
      <KleberImage src={KleberGalery} />  
      <BateraImage src={BateraGalery} /> */}

      <AllImage src={AllPhotosMobile} />

    </GaleryContainerMobile>
    </>  
  );
};

export default Galery;
