import React from "react";
import { VideosContainer, AuxVideos, VideosContainerMobile } from "./styles.js";
 
const Videos = () => {

  return (
    <>
    
    <VideosContainer id="videos">
      <iframe width="100%" height="818" src="https://www.youtube-nocookie.com/embed/wMQe86jxaLY?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <AuxVideos>
      <iframe width="50%" height="405" src="https://www.youtube-nocookie.com/embed/M5D2cjHAaWs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="50%" height="405 " src="https://www.youtube-nocookie.com/embed/fc2Zyb3zFw8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </AuxVideos>
    </VideosContainer>
    <VideosContainerMobile id="videos">
      <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/wMQe86jxaLY?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/M5D2cjHAaWs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="100%" height="240" src="https://www.youtube-nocookie.com/embed/fc2Zyb3zFw8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>   
    </VideosContainerMobile>
    </>
  );
};

export default Videos;
