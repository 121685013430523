import React from "react";
import { useSpring, animated } from "react-spring";
import RoutesPath from "./routesPath";
import Header from "./components/Header";
import Videos from "./components/Videos";
import Banda from "./components/Banda";
import Discografia from "./components/Discografia";
import Galery from "./components/Galery";
import Footer from "./components/Footer";

export default function App() {
  return (
    <>
      <head>
        <title>The900</title>
        <meta name="description" content="The900" />
        <link rel="icon" href="/the900.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Anton&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="theme-color" content="#000" />
      </head>
      <Header />
      <div style={{ overflow: "hidden", height: "100%" }}>
        <Videos />
        <Banda />
        <Discografia />
        <Galery />
        <Footer />
      </div>
      <RoutesPath />
    </>
  );
}
