import styled from "styled-components";
import colors from "../../styles/colors";

export const FooterContainer = styled.div`
  width: 100%;
  padding: 60px 0 0 0;
  display: block;

  @media (max-width: 948px) {
    display: none;
  }
`;

export const FooterContainerMobile = styled.div`
  display: none;

  @media (max-width: 948px) {
    width: 100%;
    padding: 60px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;


export const Indent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 97%;
  margin: 0 auto;

  @media (max-width: 948px) {

  }
`;

export const BlockFirst = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 948px) {

  }
`;

export const BlockSecond = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  padding: 8px 0 0 0;

  @media (max-width: 948px) {

  }
`;

export const LinkSocials = styled.a`
  color: #000;
  font-family: Anton;
  font-size: 150px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  transition: all 0.3s ease-out;
  cursor: pointer;
  text-decoration: none;

  :hover {
    transition: all 0.3s ease-out;
    color: #ff7a01;
  }

  @media (max-width: 948px) {
    color: #000;
    font-family: Anton;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; /* 114.286% */
    text-transform: uppercase;
  }
`;

export const Aux = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;


  @media (max-width: 948px) {

  }
`;

export const Number = styled.a`
  color: #000;
  text-align: right;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin: 0 0 30px 0;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  :hover::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ff7a01;
    box-shadow: 0 2px transparent;
  }

  :hover {
    color: #ff7a01;
  }

  @media (max-width: 948px) {
    margin: 0 0 40px 0;
  }
`;

export const Email = styled.a`
  color: #000;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin: 0 0 343px 0;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  :hover::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ff7a01;
    box-shadow: 0 2px transparent;
  }

  :hover {
    color: #ff7a01;
  }

  @media (max-width: 948px) {
    margin: 0 0 120px 0;
  }
`;

export const Release = styled.a`
  color: #000;
  text-align: right;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  :hover::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ff7a01;
    box-shadow: 0 2px transparent;
  }

  :hover {
    color: #ff7a01;
  }

  @media (max-width: 948px) {
    margin: 60px 0 0 0;
  }
`;

export const TextRodape = styled.p`
  color: var(--cinza-60, #666);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  margin: 94px auto 25px auto; 


  @media (max-width: 948px) {
    margin: 60px 0 15px 0;
  }
`;

export const ClickBm = styled.a`
  margin: 0 auto 94px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 948px) {
    margin: 60px 0 32px 0;
  }
`;

export const ImageBm = styled.img``;