import React, { useState } from "react";
import { DiscografiaContainer, Indent, AuxTitle, Title, BlockDisco, Image, TitleDisco, AuxData, Data, Icon, AuxBlock } from "./styles.js";
import {disco} from "./data.js";
import IconSound from "../../assets/images/icon-sound.svg";
import IconSoundOrange from "../../assets/images/icon-sound-orange.svg";

const Banda = () => {
  // Initialize the icon state array with default values
  const [iconState, setIconState] = useState(disco.map(() => IconSound));

  const handleRedirect = (link) => {
    window.open(link, '_blank');
  };

  const handleMouseOver = (index) => {
    // Update the icon state for the specific index to IconSoundOrange
    setIconState((prevIconState) => {
      const newState = [...prevIconState];
      newState[index] = IconSoundOrange;
      return newState;
    });
  }

  const handleMouseLeave = (index) => {
    // Update the icon state for the specific index to IconSound
    setIconState((prevIconState) => {
      const newState = [...prevIconState];
      newState[index] = IconSound;
      return newState;
    });
  }

  return (
    <DiscografiaContainer id="discografia">
      <Indent>
        <AuxTitle>
          <Title>
            disco<br />
            grafia
          </Title>
        </AuxTitle>
        <AuxBlock>
          {disco.map((discoItem, index) => (
            <BlockDisco
              key={discoItem.id}
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onClick={() => handleRedirect(discoItem.link)}
            >
              <Image src={discoItem.imagem} />
              <TitleDisco>{discoItem.titulo}</TitleDisco>
              <AuxData>
                <Data>{discoItem.data}</Data>
                <Icon src={iconState[index]} />
              </AuxData>
            </BlockDisco>
          ))}
        </AuxBlock>
      </Indent>
    </DiscografiaContainer>
  );
};

export default Banda;
