import Antinatural from "../../assets/images/antinatural.png";
import Aovivo from "../../assets/images/aovivo.png";
import Automatico from "../../assets/images/automatico.png";
import BonsTempos from "../../assets/images/bons-tempos.png";
import CincoVidas from "../../assets/images/cinco-vidas.png";
import EfeitoColateral from "../../assets/images/efeito-colateral.png";
import QueimandoVivo from "../../assets/images/queimando-vivo.png";

export const disco = [
  {
    id: 0,
    titulo: "5 vidas",
    data: "2023",
    link: "https://open.spotify.com/album/32WaaxrvSCfEel0tLHNBC3?si=1PcRCnj7RriaZN78TTh5sg",
    imagem: CincoVidas
  },
  {
    id: 1,
    titulo: "The900 ao vivo",
    data: "2018",
    link: "https://open.spotify.com/album/0eaIZatqwnrdNAAydAdQuC?si=J6cLf2ZETH-Um9VmLB1RFA",
    imagem: Aovivo
  },
  {
    id: 2,
    titulo: "Antinatural",
    data: "2018",
    link: "https://open.spotify.com/album/4oX4vViaq8N0mctqjoIyph?si=WNRjs29kRbCRYc4M_1_q3w",
    imagem: Antinatural
  },
  {
    id: 3,
    titulo: "Efeito Colateral",
    data: "2015",
    link: "https://open.spotify.com/album/4YPk0vmTIT8iVtOs196dWe?si=RIz7qF8BR6C4FckGDKGodA",
    imagem: EfeitoColateral
  },
  {
    id: 4,
    titulo: "Automático",
    data: "2015",
    link: "https://open.spotify.com/album/5YBVM2O9ayFAVtLT8dNlzf?si=NH3B0NUhR2moPb_K2ZbT3w",
    imagem: Automatico
  },
  {
    id: 5,
    titulo: "Queimando vivo",
    data: "2012",
    link: "https://open.spotify.com/album/4BFFGtzxEOGdQHJ44rXTZV?si=8ub_tp8DR3qj82DAfmbnxQ",
    imagem: QueimandoVivo
  },
  {
    id: 6,
    titulo: "deixe os bons tempos voltarem",
    data: "2007",
    link: "https://open.spotify.com/album/32WaaxrvSCfEel0tLHNBC3?si=1PcRCnj7RriaZN78TTh5sg",
    imagem: BonsTempos
  }
]