import styled from "styled-components";
import colors from "../../styles/colors";

export const BandaContainer = styled.div`
  width: 100%;
  background: #fff;
  padding: 0 0 50px 0;

  @media (max-width: 948px) {

  }
`;

export const Indent = styled.div`
  padding: 0 135px;  

  @media (max-width: 948px) {
    padding: 0 24px;  
  }
`;

export const AuxTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;


  @media (max-width: 948px) {
  }
`;

export const Title = styled.h3`
  color: #000;
  font-family: Anton;
  font-size: 240px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  width: 70%;
  margin: -6px 0;

  @media (max-width: 948px) {
    color: #000;
    font-family: Anton;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    width: inherit;
    margin: -8px 0 0 0;
  }
`;

export const AuxCanal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 948px) {

  }
`;

export const TextCanal = styled.p`
  color: #000;
  text-align: right;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  :hover::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ff7a01;
    box-shadow: 0 2px transparent;
  }

  :hover {
    color: #ff7a01;
  }


  @media (max-width: 948px) {
    display: none;
  }
`;

export const YoutubeImage = styled.img`
  margin: 0 0 0 33px;

  @media (max-width: 948px) {

  }
`;

export const AuxTextBanda = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  display: flex;

  @media (max-width: 948px) {

  }
`;

export const TextBanda = styled.p`
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 155.556% */
  width: 70%;

  @media (max-width: 948px) {
    color: #000;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    width: 100%;
  }
`;

export const AuxBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 50px 0 0 0;

  @media (max-width: 948px) {
    flex-direction: column;
    margin: 14px 0 0 0;
  }
`;

export const AuxImages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 948px) {

  }
`;

export const CircleImage = styled.img`
  margin: 0 30px 0 0;

  @media (max-width: 948px) {
    width: 76px;
    height: 76px;
    margin: 0 8px 0 0;
  }
`;